import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import ProductTeam from '../sections/product/ProductTeam'

const SalesTeam = () => {
  const { lang } = useLangContext()
  const { team } = useStaticQuery(graphql`
    {
      team: allContentfulZespol(sort: { order: ASC, fields: createdAt }) {
        nodes {
          teamMemberEmail
          teamMemberLinkedIn
          teamMemberName
          teamMemberPhone
          teamMemberPosition
          teamMemberBio {
            childMarkdownRemark {
              html
            }
          }
          id
          lang {
            slug
          }
          teamMemberImage {
            gatsbyImageData(layout: CONSTRAINED, width: 1000)
          }
          teamCategory
          teamOrder
        }
      }
    }
  `)

  const salesTeam = team?.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.teamCategory === true)
    .map((node) => ({
      teamMemberName: node.teamMemberName,
      teamMemberPosition: node.teamMemberPosition,
      teamMemberBio: node.teamMemberBio,
      teamMemberEmail: node.teamMemberEmail,
      teamMemberPhone: node.teamMemberPhone,
      teamMemberLinkedIn: node.teamMemberLinkedIn,
      teamMemberImage: node.teamMemberImage,
      teamOrder: node.teamOrder,
    })).sort((a, b) => a.teamOrder - b.teamOrder);
  
  return (
    <>
      {salesTeam && (
        <ProductTeam team={salesTeam} title={`Sprzedaż kontenerów`} isContact />
      )}
    </>
  )
}
export default SalesTeam
