import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/kontakt'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'

import EmailIcon from '../assets/emailIcon'
import PhoneIcon from '../assets/phoneIcon'
import LocationIcon from '../assets/locationIcon'
import FaxIcon from '../assets/faxIcon'

import MapOffices from '../sections/kontakt/MapOffices'
import ContactInfo from '../sections/kontakt/ContactInfo'
import SalesTeam from '../components/SalesTeam'
import TransportTeam from '../components/TransportTeam'
import { useFormContext } from '../context/form.context'
import FormNewsletter from '../components/FormNewsletter'
// import FormNewsletter from '../components/FormNewsletter'

const Kontakt = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kontakt.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `);

  const info = [
    {
      title: 'aleja Grunwaldzka 472C, 80-309 Gdańsk, Poland',
      link: 'https://www.google.com/maps/dir/54.389397,18.6280198/omida+logistics/',
      icon: () => <LocationIcon />,
      span: true,
    },
    // {
    //   title: 'hello@omidatrade.pl',
    //   link: 'mailto:bok@omida.pl',
    //   icon: () => <EmailIcon />,
    // },
    // {
    //   title: 'tel. +48 58 741 88 14',
    //   link: 'tel:+48587418814',
    //   icon: () => <PhoneIcon />,
    // },
    // {
    //   title: 'fax. +48 58 741 88 14',
    //   link: 'fax:+48587418814',
    //   icon: () => <FaxIcon />,
    // },
    {
      title: `${lang === 'en' ? 'TAX: PL5842739582 ' : 'NIP: 584 273 95 82 '}`,
    },
    {
      title: `${lang === 'en' ? 'NCR: 0000539585' : 'KRS: 0000539585'}`,
    },
    {
      title: 'REGON: 360603722',
    },
  ]

  const button = {
    text: {
      pl: 'Szukaj oddziału',
      en: 'Search for office',
    },
    link: '/kontakt/#oddzialy',
  }

  const buttonSecond = {
    text: {
      pl: 'Wyślij wiadomość',
      en: 'Send message',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/kontakt.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />
      {/* <FormNewsletter /> */}
      <SalesTeam />
      <TransportTeam />
      {/* <ContactInfo data={info} /> */}
      <MapOffices />
      <FormNewsletter />
    </Layout>
  )
}

export default Kontakt
